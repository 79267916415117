import refreshToken from './tokenRefresh';

const silentRefresh = () => {
  const refreshInterval = 30 * 60 * 1000; // 30 minutes

  setInterval(async () => {
    try {
      await refreshToken();
    } catch (error) {
      console.error('Silent refresh failed:', error);
    }
  }, refreshInterval);
};

export default silentRefresh;
