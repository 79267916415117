import axios from 'axios';
import { API_BASE_URL } from '../config';

const refreshToken = async () => {
  try {
    const res = await axios.post(`${API_BASE_URL}/api/users/refresh-token`, {}, {
      headers: { 'x-auth-token': localStorage.getItem('token') },
      withCredentials: true
    });
    localStorage.setItem('token', res.data.token);
    return res.data.token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export default refreshToken;
