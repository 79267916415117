import React from 'react';
import { useTranslation } from 'react-i18next';
import './Modal.css';

function Modal({ isOpen, onClose, title, message, onConfirm, confirmText, closeText }) {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="modal-buttons">
          {onConfirm && (
            <button onClick={onConfirm} className="modal-button confirm">
              {confirmText || t('confirm')}
            </button>
          )}
          <button onClick={onClose} className="modal-button cancel">
            {closeText || t('close')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
