import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import Modal from '../components/Modal';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from '../config';
import '../components/Button.css';
import axios from 'axios';

const isLoggedIn = () => {
  return !!localStorage.getItem('token');
};

function EventDetails() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [countdown, setCountdown] = useState('');
  const [canRegister, setCanRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '' });
  const [visitorNames, setVisitorNames] = useState(['', '', '']);
  const { t } = useTranslation();
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole'));
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [participantToCancel, setParticipantToCancel] = useState(null);

  const checkRegistrationStatus = useCallback((startTime) => {
    const now = new Date().getTime();
    const start = new Date(startTime).getTime();
    const timeLeft = start - now;

    if (timeLeft <= 0) {
      setCountdown(t('registrationIsOpen'));
    } else {
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
      setCountdown(t('registrationOpensIn', { days, hours, minutes, seconds }));
    }
  }, [t]);

  useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source();

    const fetchEventDetails = async () => {
      try {
        const res = await axiosInstance.get(`${API_BASE_URL}/api/events/${id}`, {
          withCredentials: true,
          cancelToken: source.token
        });
        if (isMounted) {
          setEvent(res.data);
          const now = new Date().getTime();
          const start = new Date(res.data.registrationStartTime).getTime();
          const canRegisterNow = now >= start;
          setCanRegister(canRegisterNow);
          checkRegistrationStatus(res.data.registrationStartTime);
        }
      } catch (err) {
        if (axiosInstance.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          console.error(err);
        }
      }
    };

    fetchEventDetails();

    return () => {
      isMounted = false;
      source.cancel('Component unmounted');
    };
  }, [id, checkRegistrationStatus]);

  useEffect(() => {
    if (!event) return;

    const timer = setInterval(() => {
      checkRegistrationStatus(event.registrationStartTime);
    }, 1000);

    return () => clearInterval(timer);
  }, [event, checkRegistrationStatus]);

  useEffect(() => {
    if (isLoggedIn()) {
      setVisitorNames([localStorage.getItem('username') || '', '', '']);
    }
  }, []);

  const handleRegister = async () => {
    setIsLoading(true);
    try {
      const names = visitorNames.filter(name => name.trim() !== '');
      if (names.length === 0) {
        throw new Error('At least one name is required');
      }

      const payload = isLoggedIn() ? 
        { additionalNames: names.slice(1) } : 
        { visitorNames: names };
        
      const res = await axiosInstance.post(`/api/events/${id}/register`, payload);
      const updatedEvent = await axiosInstance.get(`/api/events/${id}`);
      setEvent(updatedEvent.data);
      
      // Clear the input boxes after successful registration
      if (isLoggedIn()) {
        setVisitorNames([localStorage.getItem('username') || '', '', '']);
      } else {
        setVisitorNames(['', '', '']);
      }

      // Set appropriate message based on registration status
      let message = '';
      switch(res.data.status) {
        case 'waitlisted':
          message = t('eventFullWaitlisted');
          break;
        case 'partially_waitlisted':
          message = t('someParticipantsWaitlisted');
          break;
        default:
          message = t('registrationSuccessful');
      }

      setModalContent({
        title: t('registrationStatus'),
        message: message
      });
      setModalOpen(true);
    } catch (err) {
      console.error(err);
      setModalContent({
        title: t('error'),
        message: err.response?.data?.msg || t('registrationError')
      });
      setModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelRegistration = async () => {
    try {
      await axiosInstance.post(`/api/events/${id}/cancel`);
      const updatedEvent = await axiosInstance.get(`/api/events/${id}`);
      setEvent(updatedEvent.data);
      setModalContent({
        title: t('cancellationConfirmed'),
        message: t('registrationCancelled')
      });
      setModalOpen(true);
    } catch (err) {
      console.error(err);
      setModalContent({
        title: t('error'),
        message: err.response?.data?.msg || t('cancellationError')
      });
      setModalOpen(true);
    }
  };

  const handleCancelRegistrationByManager = async (participantId) => {
    try {
      await axiosInstance.post(`/api/events/${id}/cancel/${participantId}`);
      const updatedEvent = await axiosInstance.get(`/api/events/${id}`);
      setEvent(updatedEvent.data);
      setModalContent({
        title: t('cancellationConfirmed'),
        message: t('registrationCancelledByManager')
      });
      setModalOpen(true);
    } catch (err) {
      console.error(err);
      setModalContent({
        title: t('error'),
        message: err.response?.data?.msg || t('cancellationError')
      });
      setModalOpen(true);
    }
  };

  const handleCancelClick = (participant) => {
    setParticipantToCancel(participant);
    setConfirmModalOpen(true);
  };

  const handleConfirmCancel = async () => {
    if (participantToCancel) {
      await handleCancelRegistrationByManager(participantToCancel.userId || participantToCancel.username);
      setConfirmModalOpen(false);
      setParticipantToCancel(null);
    }
  };

  if (!event) return <div>{t('loading')}</div>;
  console.log(event);
  console.log(localStorage.getItem('userId'));
  return (
    <div className="event-details">
      <h2>{event.title}</h2>
      <p>{t('time')}: {new Date(event.time).toLocaleString()}</p>
      <p>{t('location')}: {event.location}</p>
      <div className="event-info-container">
        {t('eventInfo')}:
        <div className="event-info-block">
          {event.eventInfo ? event.eventInfo : t('noEventInfo')}
        </div>
      </div>
      <p>{t('participants')}: {event.participants.length} / {event.maxParticipants}</p>
      <p>{t('waitlist')}: {event.waitlist.length}</p>
      <p>{countdown}</p>
      <h3>{t('registeredParticipants')}</h3>
      <ul>
        {event.participants.map((participant, index) => (
          <li key={index}>
            <span className="participant-index">{index + 1}.</span>
            {(userRole === 'manager' || userRole === 'admin') && (
              <span 
                className="cancel-cross"
                onClick={() => handleCancelClick(participant)}
                title={t('cancelRegistration')}
              >
                &#10060;
              </span>
            )}
            <span className="participant-name">
              {participant.userId ? participant.username : `${participant.username} (${t('visitor')})`}
            </span>
          </li>
        ))}
      </ul>
      <h3>{t('waitlist')}</h3>
      <ul>
        {event.waitlist.map((participant, index) => (
          <li key={index}>
            <span className="participant-index">{index + 1}.</span>
            {(userRole === 'manager' || userRole === 'admin') && (
              <span 
                className="cancel-cross"
                onClick={() => handleCancelClick(participant)}
                title={t('cancelWaitlist')}
              >
                &#10060;
              </span>
            )}
            <span className="participant-name">
              {participant.userId ? participant.username : `${participant.username} (${t('visitor')})`}
            </span>
          </li>
        ))}
      </ul>
      {canRegister && (
        isLoggedIn() ? (
          event.participants.some(user => user.userId === localStorage.getItem('userId')) ? (
            <button className="btn btn-danger" onClick={handleCancelRegistration}>{t('cancelRegistration')}</button>
          ) : event.waitlist.some(user => user.userId === localStorage.getItem('userId')) ? (
            <button className="btn btn-danger" onClick={handleCancelRegistration}>{t('cancelWaitlist')}</button>
          ) : (
            <div className="registration-form">
              <div className="name-inputs">
                <input
                  type="text"
                  value={visitorNames[0]}
                  disabled
                  className="name-input"
                />
                <input
                  type="text"
                  placeholder={t('enterAdditionalName')}
                  value={visitorNames[1]}
                  onChange={(e) => {
                    const newNames = [...visitorNames];
                    newNames[1] = e.target.value;
                    setVisitorNames(newNames);
                  }}
                  className="name-input"
                />
                <input
                  type="text"
                  placeholder={t('enterAdditionalName')}
                  value={visitorNames[2]}
                  onChange={(e) => {
                    const newNames = [...visitorNames];
                    newNames[2] = e.target.value;
                    setVisitorNames(newNames);
                  }}
                  className="name-input"
                />
              </div>
              <button 
                className="btn btn-success" 
                onClick={handleRegister} 
                disabled={isLoading}
              >
                {isLoading ? t('processing') : t('registerForEvent')}
              </button>
            </div>
          )
        ) : (
          <div className="registration-form">
            <div className="name-inputs">
              {visitorNames.map((name, index) => (
                <input
                  key={index}
                  type="text"
                  placeholder={t('enterYourName')}
                  value={name}
                  onChange={(e) => {
                    const newNames = [...visitorNames];
                    newNames[index] = e.target.value;
                    setVisitorNames(newNames);
                  }}
                  className="name-input"
                />
              ))}
            </div>
            <button 
              className="btn btn-success" 
              onClick={handleRegister} 
              disabled={isLoading || !visitorNames.some(name => name.trim() !== '')}
            >
              {isLoading ? t('processing') : t('registerForEvent')}
            </button>
          </div>
        )
      )}
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={modalContent.title}
        message={modalContent.message}
      />
      
      <Modal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        title={t('confirmCancellation')}
        message={t('confirmCancellationMessage', { name: participantToCancel?.username })}
        onConfirm={handleConfirmCancel}
        confirmText={t('confirm')}
        closeText={t('cancel')}
      />
    </div>
  );
}

export default EventDetails;
