import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { useTranslation } from 'react-i18next';

function Login() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [message, setMessage] = useState('');
  const history = useHistory();
  const { t } = useTranslation();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post('/api/users/login', formData);
      if (res.data.isVerified) {
        const receivedToken = res.data.token;
        const receivedUserRole = res.data.role;
        const receivedUsername = res.data.username;
        const receivedUserId = res.data.userId;
        
        localStorage.setItem('token', receivedToken);
        localStorage.setItem('userRole', receivedUserRole);
        localStorage.setItem('username', receivedUsername);
        localStorage.setItem('userId', receivedUserId);
        
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${receivedToken}`;
        axiosInstance.defaults.headers.common['x-auth-token'] = receivedToken;
        
        window.dispatchEvent(new Event('storage'));
        history.push('/');
      } else {
        setMessage(t('pleaseVerifyEmail'));
      }
    } catch (err) {
      setMessage(t(err.response?.data?.msg || 'serverError'));
    }
  };

  return (
    <div className="login-container">
      <h2>{t('login')}</h2>
      <form onSubmit={handleSubmit}>
        {message && <div className="message error-message">{message}</div>}
        <input type="email" name="email" placeholder={t('email')} onChange={handleChange} required />
        <input type="password" name="password" placeholder={t('password')} onChange={handleChange} required />
        <button type="submit">{t('login')}</button>
        <div className="links-container">
          <Link to="/register" className="register-link">{t('dontHaveAccount')}</Link>
          <Link to="/forgot-password" className="forgot-password-link">{t('forgotPassword')}</Link>
        </div>
      </form>
    </div>
  );
}

export default Login;