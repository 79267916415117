import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          home: 'Home',
          createEvent: 'Create Event',
          adminPanel: 'Admin Panel',
          register: 'Register',
          login: 'Login',
          logout: 'Logout',
          pickleballEvents: 'Pickleball Events',
          username: 'Username',
          email: 'Email',
          role: 'Role',
          actions: 'Actions',
          delete: 'Delete',
          title: 'Title',
          time: 'Time',
          location: 'Location',
          action: 'Action',
          confirmDelete: 'Confirm Delete',
          confirmDeleteUser: 'Are you sure you want to delete this user?',
          confirmDeleteEvent: 'Are you sure you want to delete this event?',
          error: 'Error',
          errorDeletingUser: 'An error occurred while deleting the user.',
          errorDeletingEvent: 'An error occurred while deleting the event.',
          users: 'Users',
          events: 'Events',
          user: 'User',
          admin: 'Admin',
          password: 'Password',
          confirmPassword: 'Confirm Password',
          createAccount: 'Create Account',
          alreadyHaveAccount: 'Already have an account? Login',
          dontHaveAccount: "Don't have an account? Register",
          eventDetails: 'Event Details',
          participants: 'Participants',
          maxParticipants: 'Max Participants',
          registerForEvent: 'Register for Event',
          unregisterFromEvent: 'Unregister from Event',
          registrationOpens: 'Registration opens',
          eventIsFull: 'Event is full',
          eventName: 'Event Name',
          eventTime: 'Event Time',
          limitation: 'Limitation',
          registrationStartTime: 'Registration Start Time',
          registrationIsOpen: 'Registration is open!',
          registrationOpensIn: 'Registration opens in {{days}}d {{hours}}h {{minutes}}m {{seconds}}s',
          registrationStatus: 'Registration Status',
          eventFullWaitlisted: 'The event is full. You have been added to the waitlist.',
          registrationSuccessful: 'You have successfully registered for the event.',
          registrationError: 'An error occurred while registering.',
          cancellationConfirmed: 'Cancellation Confirmed',
          registrationCancelled: 'Your registration has been cancelled.',
          cancellationError: 'An error occurred while cancelling registration.',
          loading: 'Loading...',
          waitlist: 'Waitlist',
          registeredParticipants: 'Registered Participants',
          cancelRegistration: 'Cancel Registration',
          cancelWaitlist: 'Cancel Waitlist',
          processing: 'Processing...',
          manager: 'Manager',
          emailVerification: 'Email Verification',
          passwordsDontMatch: "Passwords don't match",
          pleaseVerifyEmail: 'Please verify your email before logging in, if you didn\'t receive the email, please check your spam folder',
          forgotPassword: 'Forgot Password',
          sendResetLink: 'Send Reset Link',
          resetPassword: 'Reset Password',
          newPassword: 'New Password',
          invalidCredentials: 'Invalid email or password',
          serverError: 'Server error',
          userAlreadyExists: 'User already exists',
          invalidVerificationToken: 'Invalid verification token',
          emailVerifiedSuccessfully: 'Email verified successfully',
          userNotFound: 'User not found',
          passwordResetEmailSent: 'Password reset email sent',
          invalidOrExpiredToken: 'Invalid or expired token',
          passwordResetSuccessfully: 'Password reset successfully',
          enterYourName: 'Enter your name',
          visitor: 'Visitor',
          registrationCancelledByManager: 'Registration cancelled by manager.',
          confirmCancellation: 'Confirm Cancellation',
          confirmCancellationMessage: 'Are you sure you want to cancel the registration for {{name}}?',
          confirm: 'Confirm',
          cancel: 'Cancel',
          enableImmediateRegistration: 'Enable immediate registration',
          close: 'Close',
          enterAdditionalName: 'Enter additional participant name',
          multipleRegistrationSuccess: 'Successfully registered multiple participants',
          someParticipantsWaitlisted: 'Some participants have been registered, others have been added to the waitlist.',
          eventInfo: 'Event Information',
          enterEventInfo: 'Enter event details, rules, or any additional information...',
          noEventInfo: 'No event information provided',
        },
      },
      zh: {
        translation: {
          home: '首頁',
          createEvent: '建立活動',
          adminPanel: '管理員面板',
          register: '註冊',
          login: '登錄',
          logout: '登出',
          pickleballEvents: '匹克球活動',
          username: '用戶名',
          email: '電子郵件',
          role: '角色',
          actions: '操作',
          delete: '刪除',
          title: '標題',
          time: '時間',
          location: '地點',
          action: '操作',
          confirmDelete: '確認刪除',
          confirmDeleteUser: '您確定要刪除此用戶嗎？',
          confirmDeleteEvent: '您確定要刪除此活動嗎？',
          error: '錯誤',
          errorDeletingUser: '刪除用戶時發生錯誤。',
          errorDeletingEvent: '刪除活動時發生錯誤。',
          users: '用戶',
          events: '活動',
          user: '用戶',
          admin: '系統管理員',
          password: '密碼',
          confirmPassword: '確認密碼',
          createAccount: '創建帳戶',
          alreadyHaveAccount: '已有帳戶？登錄',
          dontHaveAccount: '沒有帳戶？註冊',
          eventDetails: '活動詳情',
          participants: '參與者',
          maxParticipants: '最大參與人數',
          registerForEvent: '報名參加',
          unregisterFromEvent: '取消報名',
          registrationOpens: '報名開始時間',
          eventIsFull: '活動已滿',
          eventName: '活動名稱',
          eventTime: '活動時間',
          limitation: '限制',
          registrationStartTime: '報名開始時間',
          registrationIsOpen: '報名現已開放！',
          registrationOpensIn: '報名將在 {{days}}天 {{hours}}小時 {{minutes}}分鐘 {{seconds}}秒後開始',
          registrationStatus: '報名狀態',
          eventFullWaitlisted: '活動已滿。您已被加入候補名單。',
          registrationSuccessful: '您已成功報名參加活動。',
          registrationError: '報名時發生錯誤。',
          cancellationConfirmed: '取消確認',
          registrationCancelled: '您的報名已被取消。',
          cancellationError: '取消報名時發生錯誤。',
          loading: '加載中...',
          waitlist: '候補名單',
          registeredParticipants: '已報名參與者',
          cancelRegistration: '取消報名',
          cancelWaitlist: '取消候補',
          processing: '處理中...',
          manager: '管理員',
          emailVerification: '驗證Email',
          passwordsDontMatch: "密碼不符合",
          pleaseVerifyEmail: '請在登入前驗證您的Email，若無收到郵件，請檢查垃圾郵件信箱',
          forgotPassword: '忘記密碼',
          sendResetLink: '發送重置鏈接',
          resetPassword: '重置密碼',
          newPassword: '新密碼',
          invalidCredentials: '無效的Email或密碼',
          serverError: '伺服器錯誤',
          userAlreadyExists: '用戶已存在',
          invalidVerificationToken: '無效的驗證令牌',
          emailVerifiedSuccessfully: '郵箱驗證成功',
          userNotFound: '未找到用戶',
          passwordResetEmailSent: '密碼重置郵件已發送',
          invalidOrExpiredToken: '無效或過期的令牌',
          passwordResetSuccessfully: '密碼重置成功',
          enterYourName: '請輸入您的名稱',
          visitor: '訪客',
          registrationCancelledByManager: '報名已被管理員取消。',
          confirmCancellation: '確認取消',
          confirmCancellationMessage: '您確定要取消 {{name}} 的報名嗎？',
          confirm: '確認',
          cancel: '取消',
          enableImmediateRegistration: '立即開始報名',
          close: '關閉',
          enterAdditionalName: '輸入其他參與者姓名',
          multipleRegistrationSuccess: '成功註冊多位參與者',
          someParticipantsWaitlisted: '部分參與者已註冊，其他人已加入候補名單。',
          eventInfo: '活動資訊',
          enterEventInfo: '輸入活動詳情、規則或任何額外資訊...',
          noEventInfo: '未提供活動資訊',
        },
      },
    },
    lng: 'zh',
    fallbackLng: 'zh',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
