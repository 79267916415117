import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/axiosConfig';

function Home() {
  const [events, setEvents] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const res = await axiosInstance.get('/api/events');
        console.log('Fetched events:', res.data);
        // Sort events by time, with the closest event first
        const sortedEvents = res.data.sort((a, b) => new Date(a.time) - new Date(b.time));
        setEvents(sortedEvents);
      } catch (err) {
        console.error('Error fetching events:', err);
      }
    };
    fetchEvents();
  }, []);

  const isRegistrationOpen = (event) => {
    return new Date() >= new Date(event.registrationStartTime);
  };

  return (
    <div className="event-list-container">
      <h1>{t('pickleballEvents')}</h1>
      <div className="event-list-vertical">
        {events.map(event => (
          <Link key={event._id} to={`/event/${event._id}`} className="event-card-link">
            <div className="event-card">
              <h3>{event.title}</h3>
              <p><strong>{t('time')}:</strong> {new Date(event.time).toLocaleString()}</p>
              <p><strong>{t('location')}:</strong> {event.location}</p>
              {isRegistrationOpen(event) ? (
                <>
                  <p><strong>{t('participants')}:</strong> {event.participants.length} / {event.maxParticipants}</p>
                  <p><strong>{t('waitlist')}:</strong> {event.waitlist.length}</p>
                </>
              ) : (
                <p><strong>{t('registrationStartTime')}:</strong> {new Date(event.registrationStartTime).toLocaleString()}</p>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Home;